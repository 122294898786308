/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Third party */
@import 'instantsearch.css/themes/algolia.css';
@import 'swiper/css/bundle';
@import '@ionic/angular/css/ionic-swiper';
@import 'node_modules/@roperetail/ngx-ionic-ecommerce/assets/theme.scss';
@import '@videogular/ngx-videogular/fonts/videogular.css';

/* Overrides on RopeRetail NgxIonicEcommerce */
.normal-title-desktop {
    font-size: 2.0rem;
}

/* Custom colors */
$customColors: ('primarylight', 'cta');
@each $color in $customColors {

    .ion-color-#{$color} {
        --ion-color-base: var(--ion-color-#{$color}) !important;
        --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
        --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
        --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
    }

}

.newsletter-toast {
    transform: translateY(-75px) !important;
    --button-color: var(--ion-color-secondary);
}

.newsletter-toast::part(button) {
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 700;
    font-family: var(--normal-font);
}

.newsletter-toast::part(header) {
    font-family: var(--normal-font);
    font-weight: 700;
}

.newsletter-toast::part(message) {
    font-size: 1.2rem;
    font-family: var(--normal-font);
}

.select-size-popover {
    --width: 450px;
}

.swiper {
    .swiper-button-next, .swiper-button-prev {
        color: rgba(0,0,0,0.54);
    }
}

.search-popover {
    --min-width: 90vw;
}

.tall-modal::part(content) {
    top: calc(50% - (97%/2));
    display: block;
    height: 97%;
}

.shade-modal::part(backdrop) {
    --backdrop-opacity: 0.8;
}